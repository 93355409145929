import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from './images/logo-saigon-cafe@3x.png'

const Header = ({ siteTitle, siteInfo }) => (
    <header
        style={{
            marginTop: '2rem',
            marginBottom: `1.45rem`,
        }}
    >
        <div
            style={{
                margin: `0 auto`,
                maxWidth: 960,
                padding: `1.45rem 1.0875rem`,
            }}
        >
            <h1 style={{ margin: 0 }}>
                <Link
                    to="/"
                    style={{
                        color: `white`,
                        textDecoration: `none`,
                        display: 'block',
                        textAlign: 'center'
                    }}
                >
                    <img src={Logo}
                         title={siteTitle}
                         style={{ marginBottom: 0, width: 250}} />
                </Link>
            </h1>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <div>{ siteInfo.address }</div>
                <div>{ siteInfo.phone }</div>
                <div>{ siteInfo.openingHours }</div>
            </div>
        </div>
    </header>
)

Header.propTypes = {
    siteTitle: PropTypes.string,
    siteInfo: PropTypes.shape({
        address: PropTypes.string,
        openingHours: PropTypes.string,
        phone: PropTypes.string
    })
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
