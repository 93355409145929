/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

//import Header from "./header"
import Header from './Header/index'
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          info {
            address
            openingHours
            phone
          }
        }
      }
    }
  `)
  const { title, info } = data.site.siteMetadata
  return (
    <>

      <Header siteTitle={title} siteInfo={info} />
        <main id={'main'} className={'container'}>
            {children}
        </main>
        <footer id={'footer'} className={'container'}>
          © {new Date().getFullYear()}, Saigon Café, Cotati
        </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
